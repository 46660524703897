const unitInfoCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  },
  {
    name: name + radio + 'Status',
    title: '启/停用'
  },
  {
    name: name + radio + 'Import',
    title: '导入'
  },
  {
    name: name + radio + 'Export',
    title: '导出'
  }
]

const unitInfoCommonFor = (name) => [
  {
    name: name + 'List',
    title: '列表'
  },
  {
    name: name + 'Add',
    title: '新增'
  },
  {
    name: name + 'Modify',
    title: '修改'
  },
  {
    name: name + 'Del',
    title: '删除'
  },
  {
    name: name + 'Status',
    title: '启/停用'
  },
  {
    name: name + 'Import',
    title: '导入'
  },
  {
    name: name + 'Export',
    title: '导出'
  }
]

const projectSettingCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  }
]

const materialsAttributeCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  },
  {
    name: name + radio + 'Status',
    title: '启/停用'
  }
]

const maintenanceSettingCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Copy',
    title: '复制'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  },
  {
    name: name + radio + 'Export',
    title: '导出'
  }
]

const materialsAttributeCommonFor = (name) => [
  {
    name: name + 'List',
    title: '列表'
  },
  {
    name: name + 'Add',
    title: '新增'
  },
  {
    name: name + 'Modify',
    title: '修改'
  },
  {
    name: name + 'Del',
    title: '删除'
  },
  {
    name: name + 'Status',
    title: '启/停用'
  }
]

const processCommon = (name, radio) => {
  const res = [
    {
      name: name + radio + 'List',
      title: '列表'
    },
    {
      name: name + radio + 'Add',
      title: '新增'
    },
    {
      name: name + radio + 'Modify',
      title: '修改'
    },
    {
      name: name + radio + 'Copy',
      title: '复制'
    },
    {
      name: name + radio + 'Del',
      title: '删除'
    },
    {
      name: name + radio + 'Status',
      title: '启/停用'
    },
    {
      name: name + radio + 'Export',
      title: '导出'
    }
  ]
  if(localStorage.getItem("memberCode") === 'fsd'){
    res.push({
      name: name + radio + 'gylxImport',
      title: '工时导入'
    })
    res.push({
      name: name + radio + 'gylxExport',
      title: '工时导出'
    })
  }
  return res
}
const sparePartsCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  }
]
const procedureCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Copy',
    title: '复制'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  },
  {
    name: name + radio + 'Status',
    title: '启/停用'
  },
  {
    name: name + radio + 'Import',
    title: '导入'
  },
  {
    name: name + radio + 'Export',
    title: '导出'
  }
]
const defaultCommon = (name) => [
  {
    name: name + 'List',
    title: '列表'
  },
  {
    name: name + 'Add',
    title: '新增'
  },
  {
    name: name + 'Copy',
    title: '复制'
  },
  {
    name: name + 'Modify',
    title: '修改'
  },
  {
    name: name + 'Del',
    title: '删除'
  },
  {
    name: name + 'Status',
    title: '启/停用'
  },
  {
    name: name + 'Export',
    title: '导出'
  }
]
export default {
  unitInfoCommonFor,
  materialsAttributeCommonFor,
  unitInfo: (name) => [
    {
      name: name + 'ASupplierList',
      title: '供应商',
      children: unitInfoCommon(name, 'Supplier')
    },
    {
      name: name + 'BCustomerList',
      title: '客户',
      children: unitInfoCommon(name, 'Customer')
    }
  ],
  customerInfo: (name) => [
    {
      name: name + 'ACustomerList',
      title: '客户',
      children: unitInfoCommon(name, 'Customer')
    },
    {
      name: name + 'BCustomerSite',
      title: '现场',
      children: unitInfoCommon(name, 'CustomerSite')
    }
  ],
  factoryModel: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'AddChild',
      title: '新建下级'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  sitWarehouse: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Modify',
      title: '修改'
    }
  ],
  materialsSettingList: (name = 'materialsSettingList') => {
    const base = [
      {
        name: name + 'AProcessList',
        title: '工艺',
        children: [
          {
            name: name + 'processList',
            title: '列表'
          },
          {
            name: name + 'SetProcess',
            title: '绑定工艺'
          },
          {
            name: name + 'DelProcess',
            title: '解绑工艺'
          }
        ]
      },
      {
        name: name + 'BDrawAndFileList',
        title: '图纸及附件',
        children: [
          {
            name: name + 'drawAndFileList',
            title: '列表'
          },
          {
            name: name + 'UploadDrawing',
            title: '上传图纸'
          },
          {
            name: name + 'DelDrawing',
            title: '删除图纸'
          },
          {
            name: name + 'UploadFile',
            title: '上传附件'
          },
          {
            name: name + 'DelFile',
            title: '删除附件'
          }
        ]
      }
    ]
    if (sessionStorage.getItem('memberCode') === 'bbelc') {
      base.push({
        name: name + 'BBOMList',
        title: 'BOM',
        children: [
          {
            name: name + 'bomList',
            title: '列表'
          },
          {
            name: name + 'Modify',
            title: '编辑'
          },
          {
            name: name + 'Import',
            title: '导入'
          },
          {
            name: name + 'Export',
            title: '导出'
          }
        ]
      })
    } else if (['reagold'].includes(sessionStorage.getItem('memberCode'))) {
      base.push(...[{
        name: name + 'CInspectionProjectGroupProcessList',
        title: '过程检',
        children: [
          {
            name: name + 'inspectionProjectGroupProcessList',
            title: '列表'
          },
          {
            name: name + 'inspectionProjectGroupProcessReDefault',
            title: '恢复默认'
          },
          {
            name: name + 'inspectionProjectGroupProcessModify',
            title: '修改'
          }
        ]
      },
      {
        name: name + 'CInspectionInComingList',
        title: '来料检',
        children: [
          {
            name: name + 'inspectionInComingList',
            title: '列表'
          },
          {
            name: name + 'inspectionInComingModify',
            title: '修改'
          }
        ]
      },
      {
        name: name + 'DInspectionOutboundList',
        title: '出货检',
        children: [
          {
            name: name + 'inspectionOutboundList',
            title: '列表'
          },
          {
            name: name + 'inspectionOutboundModify',
            title: '修改'
          }
        ]
      }])
    } else {
      base.push(...[{
        name: name + 'BBOMList',
        title: 'BOM',
        children: [
          {
            name: name + 'bomList',
            title: '列表'
          },
          {
            name: name + 'Modify',
            title: '编辑'
          },
          {
            name: name + 'Import',
            title: '导入'
          },
          {
            name: name + 'Export',
            title: '导出'
          }
        ]
      }, {
        name: name + 'CInspectionProjectGroupProcessList',
        title: '过程检',
        children: [
          {
            name: name + 'inspectionProjectGroupProcessList',
            title: '列表'
          },
          {
            name: name + 'inspectionProjectGroupProcessReDefault',
            title: '恢复默认'
          },
          {
            name: name + 'inspectionProjectGroupProcessModify',
            title: '修改'
          }
        ]
      },
      {
        name: name + 'CInspectionInComingList',
        title: '来料检',
        children: [
          {
            name: name + 'inspectionInComingList',
            title: '列表'
          },
          {
            name: name + 'inspectionInComingModify',
            title: '修改'
          }
        ]
      },
      {
        name: name + 'DInspectionOutboundList',
        title: '出货检',
        children: [
          {
            name: name + 'inspectionOutboundList',
            title: '列表'
          },
          {
            name: name + 'inspectionOutboundModify',
            title: '修改'
          }
        ]
      }])
    }

    return base
  },
  processList: (name) => [
    {
      name: name + 'ACommonList',
      title: '普通',
      children: processCommon(name, 'Common')
    },
    {
      name: name + 'BReturnFixList',
      title: '返修',
      children: processCommon(name, 'ReturnFix')
    }
  ],
  processDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'CopyVersion',
      title: '复制版本'
    },
    {
      name: name + 'EditVersion',
      title: '修改版本'
    },
    {
      name: name + 'ReNameVersion',
      title: '重命名版本'
    },
    {
      name: name + 'SetMainVersion',
      title: '更改主版本'
    },
    {
      name: name + 'DelVersion',
      title: '删除版本'
    },
    {
      name: name + 'Import',
      title: '导入质检项目'
    }
  ],
  plansSettings: (name) => [
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Edit',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Import',
      title: '导入'
    }
  ],
  WorkHoursReason: (name) => [
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ],
  procedureList: (name) => [
    {
      name: name + 'AProcedure',
      title: '工序',
      children: procedureCommon(name, 'Procedure')
    },
    {
      name: name + 'BProcedureGroup',
      title: '工序段',
      children: procedureCommon(name, 'ProcedureGroup')
    }
  ],
  procedureDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Modify',
      title: '编辑'
    },
    {
      name: name + 'Synchronous',
      title: '同步至工艺流程'
    }
  ],
  station: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'SetUser',
      title: '设置人员'
    },
    {
      name: name + 'SetProcedure',
      title: '设置工序'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  materialsAttribute: (name) => [
    {
      name: name + 'AMaterialsUnitList',
      title: '计量单位',
      children: [...materialsAttributeCommon(name, 'MaterialsUnit'), {
        name: name + 'MaterialsUnitImport',
        title: '导入'
      },
      {
        name: name + 'MaterialsUnitExport',
        title: '导出'
      }]
    },
    {
      name: name + 'BMaterialsTypeList',
      title: '物料类型',
      children: materialsAttributeCommon(name, 'MaterialsType')
    },
    {
      name: name + 'CMaterialsQualityList',
      title: '物料材质',
      children: materialsAttributeCommon(name, 'MaterialsQuality')
    },
    {
      name: name + 'MaterialsAttributeList',
      title: '物料属性',
      children: []
    }
  ],
  materialsList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Detail',
      title: '详情'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'PrintCode',
      title: '打印'
    }
  ],
  bomList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Detail',
      title: '详情'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  bomDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  mbomDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'ChangeMBOM',
      title: '转MBOM'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  materialsBalance: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  materialsRequireDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Apply',
      title: '领料'
    },
    {
      name: name + 'Return',
      title: '退料'
    },
    {
      name: name + 'batchLock',
      title: '批量锁库'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  materialsRequireList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'BatchApply',
      title: '批量领料'
    },
  ],
  materialsApplyList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'AddApply',
      title: '新增领料'
    },
    {
      name: name + 'AddReturn',
      title: '新增退料'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'BatchExport',
      title: '批量导出'
    },
    {
      name: name + 'BackOut',
      title: '撤销'
    }
  ],
  materialsApplyReturnDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  materialsApplyDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  model: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'AddChild',
      title: '新建下级'
    },
    {
      name: name + 'PrintCode',
      title: '打印仓库编码'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  equipmentClass: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  packageInfo: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  trayInfo: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'PrintCode',
      title: '打印托盘编码'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  batchNumber: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'fastPage',
      title: '生成批次'
    },
    {
      name: name + 'historyPage',
      title: '历史批次'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ],
  packageCode: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'fastPage',
      title: '生成批次'
    },
    {
      name: name + 'historyPage',
      title: '历史批次'
    }
  ],
  procedureParams: (name) => defaultCommon(name),
  scarpReason: (name) => defaultCommon(name),
  sparePartsSet: (name) => [
    {
      name: name + 'OutInWarehouseType',
      title: '出入库类型',
      children: sparePartsCommon(name, 'OutInWarehouseType')
    }
  ],
  WorkShift: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Edit',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ],
  WorkCenterList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '编辑'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  ResourceList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '编辑'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  ShiftsList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '编辑'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  CalendarList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '编辑'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  equipmentAccountList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    // {
    //   name: name + 'AddRepair',
    //   title: '新建维保任务'
    // },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'PrintCode',
      title: '打印设备条码'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  equipmentAccountDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Product',
      title: '生产详情'
    },
    {
      name: name + 'Repair',
      title: '维保详情'
    }
  ],
  inspectionPlan: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ],
  inspectionPlanDetail: (name) => [
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'AddCheckItem',
      title: '新增检查项'
    }
  ],
  maintenancePlan: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ],
  maintenancePlanDetail: (name) => [
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'AddCheckItem',
      title: '新增检查项'
    }
  ],
  maintenanceSetting: (name) => [
    {
      name: name + 'AExperienceList',
      title: '维保经验',
      children: maintenanceSettingCommon(name, 'Experience')
    },
    {
      name: name + 'BFaultReasonList',
      title: '故障原因',
      children: maintenanceSettingCommon(name, 'FaultReason')
    },
    {
      name: name + 'CUrgencyLevelList',
      title: '紧急程度',
      children: maintenanceSettingCommon(name, 'UrgencyLevel')
    },
    {
      name: name + 'DEquipmentPositionList',
      title: '设备部位',
      children: maintenanceSettingCommon(name, 'EquipmentPosition')
    },
    {
      name: name + 'FlowSettingList',
      title: '流程设置',
      children: [
        {
          name: name + 'FlowSettingIsApproval',
          title: '故障报修是否需审核'
        },
        {
          name: name + 'FlowSettingIsValidate',
          title: '维修结束是否需验证'
        },
        {
          name: name + 'FlowSettingApprovalUser',
          title: '设备故障审批人员配置'
        }
      ]
    },
    {
      name: name + 'EMaintenanceProject',
      title: '保养项目',
      children: [
        ...maintenanceSettingCommon(name, 'MaintenanceProject'),
        {
          name: name + 'MaintenanceProjectImport',
          title: '导入'
        }
      ]
    },
    {
      name: name + 'FMaintenanceSetting',
      title: '保养配置',
      children: [
        ...maintenanceSettingCommon(name, 'MaintenanceSetting').slice(0, 5)
      ]
    }
  ],
  projectSetting: (name) => [
    {
      name: name + 'APointCheckItemList',
      title: '点巡检项基础',
      children: [
        ...projectSettingCommon(name, 'PointCheckItem'),
        {
          name: name + 'PointCheckItemImport',
          title: '导入'
        },
        {
          name: name + 'PointCheckItemExport',
          title: '导出'
        }
      ]
    },
    {
      name: name + 'BPointCheckTypeList',
      title: '点检类别配置',
      children: projectSettingCommon(name, 'PointCheckType')
    },
    {
      name: name + 'CInspectionList',
      title: '巡检配置',
      children: projectSettingCommon(name, 'Inspection')
    }
  ],
  equipmentParams: (name) => [
    {
      name: name + 'ACallParamsList',
      title: '参数台账',
      children: [
        {
          name: name + 'CallParamsList',
          title: '列表'
        },
        {
          name: name + 'CallParamsAdd',
          title: '新增'
        },
        {
          name: name + 'CallParamsModify',
          title: '修改'
        },
        {
          name: name + 'CallParamsDel',
          title: '删除'
        },
        {
          name: name + 'CallParamsStatus',
          title: '启用/停用'
        }
      ]
    },
    {
      name: name + 'BDataOrigin',
      title: '数据源',
      children: [
        {
          name: name + 'DataOriginList',
          title: '列表'
        },
        {
          name: name + 'DataOriginAdd',
          title: '新增'
        },
        {
          name: name + 'DataOriginSetPoint',
          title: '设置数据点'
        },
        {
          name: name + 'DataOriginModify',
          title: '修改'
        },
        {
          name: name + 'DataOriginDel',
          title: '删除'
        },
        {
          name: name + 'DataOriginStatus',
          title: '启用/停用'
        }
      ]
    }
  ],
  planOrdersList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'IssueWorkOrder',
      title: '下达工单'
    },
    {
      name: name + 'SetStation',
      title: '站号与交期'
    },
    // {
    //   name: name + 'IssuePurchaseOrder',
    //   title: '下达采购单'
    // },
    {
      name: name + 'Over',
      title: '结案'
    },
    {
      name: name + 'Restart',
      title: '重启'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ]
}
