export default {
  path: 'home',
  name: 'layout',
  redirect: '/home/fun',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '首页', icon: require('@/assets/l_c_S_images/工作台.svg'), activeMenu: 'home'},
  children: [
    {
      path: 'fun',
      name: 'Fun',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '首页', activeMenu: 'fun' },
      children :[
        {
          path: 'workspace',
          name: 'HomeWorkSpace',
          component: () => import('@/views/statistic-statement/statement-manage/index.vue'),
          //管理工作台 workSpace
          meta: {  noCache: false, title: '管理工作台', activeMenu: 'workspace', nameFlage: 'workSpace' }
        },
        {
          path: 'personal-workbench',
          name: 'HomePersonalWorkbench',
          component: () => import('@/views/statistic-statement/statement-manage/index.vue'),
          //个人工作台 personalWorkbench
          meta: {  noCache: false, title: '个人工作台', activeMenu: 'personal-workbench', nameFlage: 'personalWorkbench' }
        },
        {
          path: 'exception',
          name: 'HomeException',
          component: () => import('@/views/statistic-statement/statement-manage/index.vue'),
          //异常统计 exception
          meta: {  noCache: false, title: '异常统计', activeMenu: 'exception', nameFlage: 'exception' }
        },
        {
          path: 'workhours-statistic',
          name: 'HomeWorkHoursStatistic',
          component: () => import('@/views/statistic-statement/statement-manage/index.vue'),
          //工时统计 workHoursStatistic
          meta: {  noCache: false, title: '项目工时统计', activeMenu: 'workhours-statistic', nameFlage: 'workHoursStatistic' }
        },
        {
          path: 'orderhours-statistic',
          name: 'OrderHoursStatistic',
          component: () => import('@/views/statistic-statement/statement-manage/index.vue'),
          meta: {  noCache: false, title: '订单工时统计', activeMenu: 'orderhours-statistic', nameFlage: 'orderHoursStatistic' }
        },
        {
          path: 'abnormal-statistics',
          name: 'HomeAbnormalStatistics',
          component: () => import('@/views/statistic-statement/statement-manage/index.vue'),
          //全场呼叫的异常统计 AbnormalStatistics
          meta: {  noCache: false, title: '异常统计', activeMenu: 'abnormal-statistics', nameFlage: 'AbnormalStatistics' }
        }
      ]
    }
  ]
}


// export default {
//   path: 'home',
//   name: 'layout',
//   // component: () => import('@/views/home/home.vue'),
//   component: () => import('@/views/statistic-statement/statement-manage/index.vue'),
//   meta: { title: '首页', icon: require('@/assets/l_c_S_images/工作台.svg'), activeMenu: 'home'}
//   // children: [
//   //   {
//   //     path: 'index',
//   //     name: 'home',
//   //     component: () => import('@/views/home/home.vue'),
//   //     meta: { title: '统计', activeMenu: 'home', nb: true }
//   //   },
//   //   // {
//   //   //   path: 'statistics',
//   //   //   name: 'statistics',
//   //   //   component: () => import('@/views/home/statistics/statistics.vue'),
//   //   //   meta: { title: '统计', activeMenu: 'statistics', nb: true }
//   //   // }
//   // ]
// }
