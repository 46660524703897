import warehouseButtons from './page-buttons/warehouse.buttons'
import siteWarehouseButtons from './page-buttons/site-warehouse.buttons'
export default {
  path: 'warehouse',
  name: 'warehouse',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '库存管理', icon: require('@/assets/l_c_S_images/仓库管理.svg'), activeMenu: 'warehouse' },
  redirect: '/warehouse/receive-info/receive',
  children: [
    {
      path: 'receive-info',
      name: 'receiveInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '收料管理', activeMenu: 'receive-info' },
      redirect: '/warehouse/receive-info/receive-material',
      children: [
        {
          path: 'receive-material',
          name: 'receiveMaterialList',
          component: () => import('@/views/warehouse/receive-material/list.vue'),
          meta: { noCache: true, title: '收料列表', activeMenu: 'receive-material', buttons: warehouseButtons.receiveMaterialBtn('receiveMaterialList'), }
        }
      ]
    },
    {
      path: 'inventory-info',
      name: 'inventoryInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '仓库管理', activeMenu: 'inventory-info' },
      redirect: '/warehouse/inventory-info/inventory',
      children: [
        {
          path: 'inventory',
          name: 'inventory',
          component: () => import('@/views/warehouse/inventory/inventory.vue'),
          meta: { noCache: false, title: '库存管理', activeMenu: 'inventory', buttons: warehouseButtons.inventory('inventory') }
        },
        {
          path: 'purchase-warehouse-task',
          name: 'purchaseWarehouseTask',
          component: () => import('@/views/warehouse/purchase-warehouse-task/list.vue'),
          meta: { noCache: true, title: '采购入库任务', activeMenu: 'purchase-warehouse-task'}
        },  
        // {
        //   path: 'warehouse-assign',
        //   name: 'warehouseAssign',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '库存任务', activeMenu: 'warehouse-assign', canLink: true },
        //   redirect: '/warehouse/inventory-info/warehouse-assign/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'warehouseAssignList',
        //       component: () => import('@/views/warehouse/warehouse-assign/warehouse-assign.vue'),
        //       meta: {
        //         noCache:false,
        //         roleTitle: '库存列表',
        //         title: '',
        //         activeMenu: 'warehouse-assign',
        //         buttons: warehouseButtons.warehouseAssignList('warehouseAssignList')
        //       }
        //     },
        //     {
        //       path: 'detail',
        //       name: 'warehouseAssignDetail',
        //       component: () => import('@/views/warehouse/warehouse-assign/detail/detail.vue'),
        //       meta: { title: '备料详情', activeMenu: 'warehouse-assign', nb: true, buttons: warehouseButtons.warehouseAssignDetail('warehouseAssignDetail')}
        //     },
        //     {
        //       path: 'apply-assign-detail',
        //       name: 'ApplyAssignDetail',
        //       component: () => import('@/views/warehouse/warehouse-assign/detail/apply-assign-detail.vue'),
        //       meta: { title: '领料任务详情', noCache:true, activeMenu: 'warehouse-assign', nb: true, buttons: warehouseButtons.applyAssignDetail('ApplyAssignDetail')}
        //     },
        //     {
        //       path: 'return-assign-detail',
        //       name: 'ReturnAssignDetail',
        //       component: () => import('@/views/warehouse/warehouse-assign/detail/return-assign-detail.vue'),
        //       meta: { title: '退料任务详情', noCache:true, activeMenu: 'warehouse-assign', nb: true, buttons: warehouseButtons.returnAssignDetail('ReturnAssignDetail')}
        //     }
        //   ]
        // },
        {
          path: 'preparation-task',
          name: 'PreparationTask',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '备料任务', activeMenu: 'preparation-task', canLink: true },
          redirect: '/warehouse/inventory-info/preparation-task/list',
          children: [
            {
              path: 'list',
              name: 'PreparationTaskList',
              component: () => import('@/views/warehouse/warehouse-assign/warehouse-assign.vue'),
              meta: {
                noCache:false,
                roleTitle: '任务列表',
                title: '任务列表',
                activeMenu: 'preparation-task',
                buttons: warehouseButtons.warehouseAssignBtn('PreparationTaskList'),
                nameFlage:'WarehouseAssign'
              }
            },
            {
              path: 'detail',
              name: 'warehouseAssignDetail',
              component: () => import('@/views/warehouse/warehouse-assign/detail/detail.vue'),
              meta: { title: '备料详情', activeMenu: 'preparation-task', nb: true, buttons: warehouseButtons.warehouseAssignDetail('warehouseAssignDetail')}
            },
          ]
        },
        {
          path: 'picking-task',
          name: 'PickingTask',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '领料任务', activeMenu: 'picking-task', canLink: true },
          redirect: '/warehouse/inventory-info/picking-task/list',
          children: [
            {
              path: 'list',
              name: 'PickingTaskList',
              component: () => import('@/views/warehouse/warehouse-assign/warehouse-assign.vue'),
              meta: {
                noCache:false,
                roleTitle: '任务列表',
                title: '任务列表',
                activeMenu: 'picking-task',
                buttons: warehouseButtons.warehouseAssignBtn('PickingTaskList'),
                nameFlage:'ApplyAssign'
              }
            },
            {
              path: 'apply-assign-detail',
              name: 'ApplyAssignDetail',
              component: () => import('@/views/warehouse/warehouse-assign/detail/apply-assign-detail.vue'),
              meta: { title: '领料任务详情', noCache:true, activeMenu: 'picking-task', nb: true, buttons: warehouseButtons.applyAssignDetail('ApplyAssignDetail')}
            }
          ]
        },
        {
          path: 'scraping-task',
          name: 'ScrapingTask',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '退料任务', activeMenu: 'scraping-task', canLink: true },
          redirect: '/warehouse/inventory-info/scraping-task/list',
          children: [
            {
              path: 'list',
              name: 'ScrapingTaskList',
              component: () => import('@/views/warehouse/warehouse-assign/warehouse-assign.vue'),
              meta: {
                noCache:false,
                roleTitle: '任务列表',
                title: '任务列表',
                activeMenu: 'scraping-task',
                buttons: warehouseButtons.warehouseAssignBtn('ScrapingTaskList'),
                nameFlage:'ReturnAssign'
              }
            },
            {
              path: 'return-assign-detail',
              name: 'ReturnAssignDetail',
              component: () => import('@/views/warehouse/warehouse-assign/detail/return-assign-detail.vue'),
              meta: { title: '退料任务详情', noCache:true, activeMenu: 'scraping-task', nb: true, buttons: warehouseButtons.returnAssignDetail('ReturnAssignDetail')}
            }
          ]
        },
        {
          path: 'warehouse-record',
          name: 'warehouseRecord',
          component: () => import('@/views/spareParts/warehouse-record/warehouse-record.vue'),
          meta: { title: '库存记录', activeMenu: 'warehouse-record', buttons: warehouseButtons.warehouseRecord('warehouseRecord') }
        },
        {
          path: 'warehouse-operate',
          name: 'warehouseOperate',
          component: () => import('@/views/spareParts/warehouse-operate/warehouse-operate'),
          meta: { title: '新增', activeMenu: 'warehouse-record', nb: true, noCache: true },
          hidden: true
        },
        {
          path: 'warehouse-operate-detail',
          name: 'warehouseOperateDetail',
          component: () => import('@/views/spareParts/warehouse-operate-detail/warehouse-operate-detail'),
          meta: { title: '详情', activeMenu: 'warehouse-record', nb: true, noCache: true },
          hidden: true
        },
        {
          path: 'inventory-account',
          name: 'inventoryAccount',
          component: () => import('@/views/warehouse/inventory-account/inventory-account'),
          meta: { noCache: false, title: '库存流水', activeMenu: 'inventory-account', buttons: warehouseButtons.inventoryAccount('inventoryAccount') }
        },
        // {
        //   path: 'purchase',
        //   name: 'purchase',
        //   component: () => import('@/views/warehouse/purchase/purchase'),
        //   meta: { noCache: false, title: '采购申请', activeMenu: 'purchase', buttons: warehouseButtons.purchase('purchase') }
        // }
      ]
    },
    {
      path: 'warehouses-info',
      name: 'warehousesInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '线边管理', activeMenu: 'warehouses-info' },
      children: [
        // {
        //   path: 'warehouses',
        //   name: 'warehouses',
        //   component: () => import('@/views/site-warehouse/warehouse/warehouse.vue'),
        //   meta: { title: '线边库存', activeMenu: 'warehouses', buttons: siteWarehouseButtons.warehouses }
        // },
        {
          path: 'warehouses-materials',
          name: 'WarehousesMaterials',
          component: () => import('@/views/site-warehouse/warehouse/warehouse.vue'),
          meta: {
            title: '线边物料',
            activeMenu: 'warehouses-materials',
            buttons: siteWarehouseButtons.WarehousesMaterials("WarehousesMaterials"),
            nameFlage:"Materials"
          }
        },
        {
          path: 'warehouses-packaging',
          name: 'WarehousesPackaging',
          component: () => import('@/views/site-warehouse/warehouse/warehouse.vue'),
          meta: {
            title: '线边包装',
            activeMenu: 'warehouses-packaging',
            buttons: siteWarehouseButtons.WarehousesPackaging("WarehousesPackaging"),
            nameFlage:"Package"
          }
        },
        {
          path: 'warehouses-tray',
          name: 'WarehousesTray',
          component: () => import('@/views/site-warehouse/warehouse/warehouse.vue'),
          meta: {
            title: '线边托盘',
            activeMenu: 'warehouses-tray',
            buttons: siteWarehouseButtons.WarehousesTray("WarehousesTray"),
            nameFlage:"Tray"
          }
        },
        {
          path: 'sit-warehouse-account',
          name: 'sitWarehouseAccount',
          component: () => import('@/views/site-warehouse/sitWarehouseAccount/sitWarehouseAccount.vue'),
          meta: { title: '线边流水', activeMenu: 'sit-warehouse-account', buttons: siteWarehouseButtons.sitWarehouseAccount('sitWarehouseAccount') }
        }
      ]
    }
  ]
}
